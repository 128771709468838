import React, { useState, useEffect } from 'react'

import {get_race} from '../api/race'
import {get_teams} from '../api/teams'
import {get_riders} from '../api/riders'
import incident_manager from '../api/incident'

import {format_date_short} from '../util/utils'
import {riderToEditableList, cleanUpNullValues} from '../util/incidentEditor'

import {IncidentCreatorStepOne} from './create_incident_1'
import {IncidentCreatorStepTwo} from './create_incident_2'
import {IncidentCreatorStepThree} from './create_incident_3'

import {useHistory} from 'react-router-dom'

export const RaceIncidentForm = (props)=>{
    let {race_id, incident_id, formState} = props
    let history = useHistory()
    let formSteps = 3

    let [raceName, setRaceName] = useState("...");
    let [raceDate, setRaceDate] = useState("...")
    let [currentStep, setCurrentStep] = useState(1);
    let [raceObj, setRaceObj] = useState(null);
    let [teams, setTeams] = useState([])
    let [riders, setRiders] = useState([])
    let [message, setMessage] = useState([])
	
	const [participants, setParticipants] = useState([]);

    useEffect(() => {
        if(race_id !== ""){
            get_race(race_id).then((data)=>{
                setRaceObj(data);
                updateState('incident_timestamp', new Date(data['start_datetime']))
                setRaceName(data['race_edition']['race']['name'])
                setRaceDate(format_date_short(state['incident_timestamp']))				

                setParticipants(data.riders.map((rider)=> ({
                    riderbib: rider.bib_number,
                    riderid: rider.rider_id,
                    uciid: rider.uci_id
                })));
            })		
        }
    }, [race_id])

    useEffect(()=>{
        loadIncident()
    }, [incident_id])

    useEffect(()=>{
        if(raceObj && participants){
            get_teams(new Date(raceObj.start_datetime).getFullYear()).then((data)=>{
                var options = []
                options.push({value: "", label: 'ALL TEAMS'})
                for(const [key, team] of Object.entries(data)){
                    options.push({
                        value: team['team_code'],
                        label: team['team_name']
                    })
                }
                setTeams(options)
            })
            loadRiders(new Date(raceObj.start_datetime).getFullYear(), null);
        }
    }, [raceObj, participants])

    let [state, setState] = useState({
            "incident_severity": 0,
			"incident_number_of_riders": 0,
            "incident_location_latitude": null,
            "incident_location_longitude": null,
            "incident_description": "",
			"incident_summary": "",
            "incident_timestamp": new Date(),
            "incident_status": "NEW",
            "incident_source": "USER",
            "incident_race_stage_id": parseInt(race_id),
            "incident_road_type": "UNKNOWN",
			"incident_type": null,
            "incident_environment_type": null,
            "incident_audience_quantity": 0,
            "incident_km_from_finish": null,
            "incident_involved_riders": [],
            "incident_causes": []
    })

    const loadIncident = () =>{
        if(incident_id!==""){
            incident_manager.get_incident(parseInt(incident_id)).then((data)=>{
                var data_cpy = {...data}
                var causes = data_cpy['causes']
                var riders = data_cpy['riders']
                delete data_cpy.causes
                delete data_cpy.riders
                delete data_cpy.stage
                data_cpy.incident_race_stage_id = data_cpy.incident_race_stage
                delete data_cpy.incident_race_stage

                data_cpy.incident_causes = Object.values(causes)
                data_cpy.incident_involved_riders = riderToEditableList(Object.values(riders))
                data_cpy.incident_timestamp = new Date(data_cpy.incident_timestamp)
                setRaceName(data.stage.race_edition.race.name)
                setRaceDate(format_date_short(new Date(data.stage.start_datetime)))
                setRaceObj(data.stage)
                				
				get_race(data.stage.race_stage_id).then((data)=>{
					var local_participants = []
					data.riders.map((rider)=>{
						local_participants.push({
							riderbib: rider.bib_number,
							riderid: rider.rider_id,
							uciid: rider.uci_id
						})					
					})				
					setParticipants(local_participants)		
				})				
                setState(data_cpy)
            });
        }
    }

    const updateState = (state_key, value)=>{
        const state_cpy = {...state}
		if(state_key == "incident_type"){
			if(value == ""){
				value = null
			}
		}
        state_cpy[state_key]=value
        setState(state_cpy)
    }

    const handleChange = (evt)=>{
        const {name, value}=evt.target
        updateState(name, value)
    }
    
    const handleIntChange = (evt)=>{
        const {name, value} = evt.target
        updateState(name, parseInt(value))
    }

    const handleDoubleChange = (evt) =>{
        const {name, value} = evt.target
        updateState(name, parseFloat(value))
    }

    const handleDateChange = (evt) =>{
        const {name, value}=evt.target
        if(value){
            let original_datetime = state[name]
            let new_datetime = new Date(value)

            original_datetime.setDate(new_datetime.getDate())
            original_datetime.setMonth(new_datetime.getMonth())
            original_datetime.setFullYear(new_datetime.getFullYear())

            updateState(name, original_datetime);
        }
    }

    const setMessageTimeout = (msg)=>{
        setMessage(msg)
        setTimeout(()=>{
            setMessage("")
        }, 5000)
    }

    const handleTimeChange = (evt) =>{
        const {name, value}=evt.target
        if(value){
            let original_datetime = state[name]
            let parts = value.split(":")

            original_datetime.setHours(parseInt(parts[0]), parseInt(parts[1])-original_datetime.getTimezoneOffset(), parseInt(parts[2]))
            updateState(name, original_datetime);
        }
    }

    const loadRiders = (season, team) =>{
        get_riders(season, team).then((data)=>{
            var options = []
            if(participants.length>0){
				for(const [key, rider] of Object.entries(data)){
					let participant = participants.find(participant => participant.riderid == rider['rider_id']);														
					let bib= participant ? participant.riderbib : false; 
				
					if (bib){
                
					options.push({
						label: rider['last_name']+" "+rider["first_name"]+" - "+bib+"",
						value: rider['rider_id'],
						team: rider['resolved_team']['team_name']+" ("+rider["resolved_team"]["team_code"]+")"
					})
				
					}
				}
			}
			else{
				for(const [key, rider] of Object.entries(data)){
					options.push({
						label: rider['last_name']+" "+rider["first_name"],
						value: rider['rider_id'],
						team: rider['resolved_team']['team_name']+" ("+rider["resolved_team"]["team_code"]+")"
					})
				
				}				
			}
			
		
            setRiders(options)            
        })
    }
    const teamCallback = (selectedOption)=>{
        if(raceObj){
            var team_code = selectedOption.value.length>0?selectedOption.value:null;
            loadRiders(new Date(raceObj.start_datetime).getFullYear(), team_code);
        }
    }

    const riderCallback = (involved_rider) =>{
        var involvedRiders = state.incident_involved_riders
        involvedRiders.push(involved_rider)
        setState({...state, incident_involved_riders: involvedRiders})
    }

    const removeRiderCallback = (rider_id) => {
        var i=0;
        var involvedRiders = state.incident_involved_riders;

        while(i<involvedRiders.length){
            if(involvedRiders[i].rider_id===rider_id)
                involvedRiders.splice(i, 1);
            else
                ++i;
        }
        setState({...state, incident_involved_riders: involvedRiders})
    }

    const editRiderCallback = (evt, rider_id) => {
        const {name, value}=evt.target
        console.log(name);
        console.log(value);
        var i=0;
        var involvedRiders = state.incident_involved_riders;

        while(i<involvedRiders.length){
            if(involvedRiders[i].rider_id===rider_id){
				console.log(involvedRiders[i])
				if(name == "incident_rider_fault")
					involvedRiders[i][name]=!involvedRiders[i][name]
                else
					involvedRiders[i][name]=value
			}
            ++i;
        }
        setState({...state, incident_involved_riders: involvedRiders})
    }


    const causeCallback = (cause) =>{
        var incident_causes = state.incident_causes;
        incident_causes.push(cause)
        setState({...state, incident_causes: incident_causes})
    }

    const removeCauseCallback = (cause_id) =>{
        var i=0;
        var involvedCauses = state.incident_causes;

        while(i<involvedCauses.length){
            if(involvedCauses[i].cause_id===cause_id)
                involvedCauses.splice(i, 1);
            else
                ++i;
        }
        setState({...state, incident_causes: involvedCauses})
    }

    //Save the values to the database
    const create_edit = () =>{
        var state_cpy = cleanUpNullValues(state)
        incident_manager.create_incident(state_cpy).then((incident_id)=>{
            console.log("Successfully created an incident "+incident_id)
            if(currentStep == formSteps){
                history.push(`/incident/${incident_id}`)
            }
            else{
                setMessageTimeout("Successfully edited incident "+incident_id)
                loadIncident()
            }
        }).catch((err)=>{
            console.log("ERROR "+err)
        })
    }

    const _next = ()=>{
        // If the current step is 1 or 2, then add one on "next" button click
        let newCurrentStep = currentStep >= formSteps ? formSteps: currentStep + 1
        setCurrentStep(newCurrentStep)
    }

    const _prev = ()=>{
        // If the current step is 1 or 2, then add one on "next" button click
        let newCurrentStep = currentStep <=1 ? 1: currentStep - 1
        setCurrentStep(newCurrentStep)
    }

    var previousBtn = ()=>{
        if(currentStep > 1){
          return (
            <button 
            className="btn btn-theme float-left" 
            type="button" 
            onClick={_prev}>
              Previous
            </button> 
          )
        }
        return null
    }

    var nextBtn = ()=>{
        if(currentStep < formSteps){
          return (
            <button 
            className="btn btn-theme float-right" 
            type="button" 
            onClick={_next}>
              Next
            </button> 
          )
        }
        return null
    }

    var createBtn = () =>{
        return (
                <button
                className="btn btn-success float-right"
                type="button"
                onClick={create_edit}>
                    {formState}
                </button>
            )
    }
    return (
            <div>
                <h1>Report incident - {raceName} ({raceDate}) </h1>
                <div className='progressArea'>
                    <p>{currentStep}/{formSteps}</p>
                    <div className="progress">
                        <div className="progress-bar bg-info" role="progressbar" style={{width: (currentStep-1)/(formSteps-1)*100+'%'}} aria-valuenow={(currentStep-1)/(formSteps-1)*100} aria-valuemin="0" aria-valuemax="100"></div>
                    </div>
                </div>
                <div className='race-information'>
                    <h4>General stage and race information</h4>
                    <ul className='list-inline'>
                        <li className='list-inline-item'><b>{raceName}</b></li>
                        <li className='list-inline-item'>({raceDate?raceDate:'...'})</li>
                        <li className='list-inline-item'><span className='badge badge-secondary'>{raceObj?(raceObj.stage_index>0?`Stage ${raceObj.stage_index}`:`one day`):'...'}</span></li>
                    </ul>
                </div>
                <form>
                    {currentStep!==1?(
                    <div className='form-group'>
                        <label htmlFor="incident_description">Description of the incident</label>
                        <textarea placeholder='Incident description' id='incident_description'
                                            className='form-control' 
                                            value={state.incident_description} name='incident_description' 
                                            onChange={handleChange}
                                            readOnly={currentStep!==1}
                        />
                    </div>):("")}
                    <IncidentCreatorStepOne
                        currentStep={currentStep}
                        handleChange={handleChange}
                        handleIntChange={handleIntChange}
                        handleDoubleChange={handleDoubleChange}
                        handleDateChange={handleDateChange}
                        handleTimeChange={handleTimeChange}
                        incident_severity={state.incident_severity}
						incident_number_of_riders={state.incident_number_of_riders}
						incident_summary={state.incident_summary}
                        incident_timestamp={state.incident_timestamp}
                        incident_road_type={state.incident_road_type}
						incident_type={state.incident_type}
                        incident_audience_quantity={state.incident_audience_quantity}
                        incident_environment_type={state.incident_environment_type}
                        incident_description={state.incident_description}
                        incident_km_from_finish={state.incident_km_from_finish}
						incident_location_latitude={state.incident_location_latitude}
						incident_location_longitude={state.incident_location_longitude}						
                    />                
                    <IncidentCreatorStepTwo
                        currentStep={currentStep}
                        handleChange={handleChange}
                        raceDate={raceDate}
                        teams={teams}
                        riders={riders}
                        teamCallback={teamCallback}
                        riderCallback={riderCallback}
                        removeRiderCallback={removeRiderCallback}
                        editRiderCallback={editRiderCallback}
                        involvedRiders={state.incident_involved_riders}
                    />
                    <IncidentCreatorStepThree
                        currentStep={currentStep}
                        causeCallback={causeCallback}
                        removeCauseCallback={removeCauseCallback}
                        selectedCauses={state.incident_causes}
                    />
                </form>
                <div className='row multiform-navigation'>
                    {previousBtn()}
                    {createBtn()}
                    {nextBtn()}
                </div>
                <div className='message'>{message}</div>


            </div>
    )

}